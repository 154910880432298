const accountPrefix = '/account';
const dashboardPrefix = '/dashboard';
const getTheLookPrefix = '/get-the-look';
const curateTheLookPrefix = '/curate-the-look';
const requestTheLookPrefix = '/request-the-look';
const ambassadorPrefix = '/ambassador';
const lookBoardPrefix = '/look-board';
const inspirationImagePrefix = '/inspiration-image';
const productPrefix = '/product';
const showcasePrefix = '/showcase';
const reportPrefix = '/report';

export const routesByName = {
  home: '/',
  auth: {
    key: 'auth',
    signIn: 'sign-in',
    signUp: 'sign-up',
    emailVerification: '/auth/email-verification/:userId/:token',
    resetPassword: '/auth/password/reset/:token',
  },
  account: {
    index: accountPrefix,
    profile: `${accountPrefix}/profile`,
    // orders: `${accountPrefix}/orders`,
    // paymentDetails: `${accountPrefix}/payment-details`,
  },
  dashboard: {
    index: dashboardPrefix,
    points: `${dashboardPrefix}/points`,
    images: `${dashboardPrefix}/images`,
    lookBoards: `${dashboardPrefix}/look-boards`,
    requests: `${dashboardPrefix}/requests`,
    community: `${dashboardPrefix}/community`,
    messages: `${dashboardPrefix}/messages`,
    ambassadorManagement: `${dashboardPrefix}/ambassador-management`,
    ambassadorVideos: `${dashboardPrefix}/ambassador-videos`,
    ambassadorPrograms: `${dashboardPrefix}/ambassador-programs`,
    ambassadorPointsBadges: `${dashboardPrefix}/ambassador-points-badges`,
    ambassadorShowcase: `${dashboardPrefix}/ambassador-showcase`,
    ambassadorTeam: `${dashboardPrefix}/ambassador-team`,
    ambassadorFAQs: `${dashboardPrefix}/ambassador-faqs`,
  },
  getTheLook: {
    index: getTheLookPrefix,
    oldDetails: `${getTheLookPrefix}/:id/:slug`,
    ambassadors: {
      index: `${getTheLookPrefix}/ambassadors`,
      details: (userId = ':userId') => `${getTheLookPrefix}/ambassadors/${userId}`,
    },
    users: {
      index: `${getTheLookPrefix}/users`,
      details: (userId = ':userId') => `${getTheLookPrefix}/users/${userId}`,
    },
    followedAmbassadors: {
      index: `${getTheLookPrefix}/followed-ambassadors`,
    },
    mixMatch: {
      index: `${getTheLookPrefix}/mix-match`,
      details: (imageId = ':imageId', slug = ':imageSlug') =>
        `${getTheLookPrefix}/mix-match/${imageId}/${slug}`,
      lookBoards: {
        index: `${getTheLookPrefix}/mix-match/look-boards`,
        details: (imageId = ':imageId') => `${getTheLookPrefix}/mix-match/look-boards/${imageId}`,
      },
    },
    lookBoard: {
      details: (lookBoardId = ':lookBoardId', slug = ':lookBoardSlug') =>
        `${getTheLookPrefix}/look-board/${lookBoardId}/${slug}`,
    },
    lookBoardView: {
      index: `${getTheLookPrefix}/look-board-view`,
      details: (imageId = ':imageId', slug = ':imgSlug') =>
        `${getTheLookPrefix}/look-board-view/${imageId}/${slug}`,
    },
    products: {
      index: `${getTheLookPrefix}/show-the-look`,
      room: `${getTheLookPrefix}/show-the-look/room-images`,
      productType: `${getTheLookPrefix}/show-the-look/product-type`,
      details: (imageId = ':imageId', slug = ':imgSlug') =>
        `${getTheLookPrefix}/shop-the-look/${imageId}/${slug}`,
    },
  },
  curateTheLook: {
    index: curateTheLookPrefix,
    canvas: `${curateTheLookPrefix}/canvas`,
    canvasBySlug: (slug = ':requestSlug') => `${curateTheLookPrefix}/canvas/${slug}`,
    details: `${curateTheLookPrefix}/details`,
    submit: `${curateTheLookPrefix}/submit`,
    success: `${curateTheLookPrefix}/success`,
  },
  requestTheLook: {
    index: requestTheLookPrefix,
    details: `${requestTheLookPrefix}/details`,
    submit: `${requestTheLookPrefix}/submit`,
    success: `${requestTheLookPrefix}/success`,
    ambassadorMatch: (requestId = ':requestId') =>
      `${requestTheLookPrefix}/ambassador-match/${requestId}`,
    recommendations: (requestId = ':requestId') =>
      `${requestTheLookPrefix}/recommendations/${requestId}`,
  },
  ambassador: {
    index: ambassadorPrefix,
    becomeAmbassador: `${ambassadorPrefix}/become-ambassador`,
    faqs: `${ambassadorPrefix}/faqs`,
  },
  lookBoard: {
    index: lookBoardPrefix,
    details: (lookBoardId = ':lookBoardId', lookBoardSlug = ':lookBoardSlug') =>
      `${lookBoardPrefix}/${lookBoardId}/${lookBoardSlug}`,
  },
  inspirationImage: {
    index: inspirationImagePrefix,
    details: (imageId = ':imageId', imageSlug = ':imageSlug') =>
      `${inspirationImagePrefix}/${imageId}/${imageSlug}`,
  },
  product: {
    index: productPrefix,
    details: (productId = ':productId', slug = ':productSlug') =>
      `${productPrefix}/${productId}/${slug}`,
  },
  showcase: {
    preview: `${showcasePrefix}/preview`,
    details: (urlKey = ':urlKey') => `${showcasePrefix}/${urlKey}`,
  },
  report: {
    index: reportPrefix,
    copyrightInfringement: `${reportPrefix}/imageremovalrequest`,
    getCredit: `${reportPrefix}/getCredit`,
  },
  nominateAmbassador: '/nominate-ambassador',
  tradeOpportunities: '/trade-opportunities',
  dialogs: '/dialogs',
  forums: '/forums',
  about: '/about',
  contact: '/contact',
  blog: '/blog',
  terms: '/terms',
  faqs: '/faqs',
  privacy: '/privacy',
  community: '/community-guidelines',
  howItworks: '/how-it-works',
  getStartedNow: '/get-started-now',
  unavailableTool: '/unavailable-tool',
  styleQuiz: '/style-quiz',
  getThePin: '/get-the-pin',
  routeWithParams(param = ':param') {
    return `/prefix/${param}`;
  },
};

export default {
  routesByName,
};
